var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gl-disclosure-dropdown-item',{staticClass:"ci-job-component",class:[
    _vm.cssClassJobName,
    {
      'gl-text-gray-900 hover:gl-no-underline focus:gl-no-underline active:gl-no-underline':
        _vm.hasDetails,
      'js-job-component-tooltip non-details-job-component': !_vm.hasDetails,
    },
  ],attrs:{"item":_vm.item,"data-testid":_vm.testid},scopedSlots:_vm._u([{key:"list-item",fn:function(){return [_c('div',{staticClass:"-gl-my-1 -gl-ml-2 gl-flex gl-items-center gl-justify-between"},[_c('job-name-component',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip.viewport.left",modifiers:{"viewport":true,"left":true}}],attrs:{"title":_vm.tooltipText,"name":_vm.job.name,"status":_vm.job.status,"data-testid":"job-name"}}),_vm._v(" "),(_vm.hasJobAction)?_c('action-component',{attrs:{"tooltip-text":_vm.jobActionTooltipText,"link":_vm.status.action.path,"action-icon":_vm.status.action.icon,"should-trigger-click":_vm.shouldTriggerActionClick,"with-confirmation-modal":_vm.withConfirmationModal},on:{"showActionConfirmationModal":_vm.showActionConfirmationModal}}):_vm._e(),_vm._v(" "),(_vm.showConfirmationModal)?_c('gl-modal',{ref:"modal",attrs:{"modal-id":"action-confirmation-modal","title":_vm.confirmationTitle,"action-cancel":_vm.$options.i18n.confirmationModal.actionCancel,"action-primary":_vm.confirmationActionPrimary},on:{"primary":_vm.executePendingAction},model:{value:(_vm.showConfirmationModal),callback:function ($$v) {_vm.showConfirmationModal=$$v},expression:"showConfirmationModal"}},[_c('div',[_c('p',[_vm._v(_vm._s(_vm.confirmationMessage))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$options.i18n.confirmationModal.confirmationText))])])]):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }